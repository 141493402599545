import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const PurchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    addPurchase(state, action) {
      state.data = action.payload.data;
    },
    removepurchase(state, action) {
      return state.filter((item: any) => item.id !== action.payload);
    },
    setPurchasePrint(state, action) {
      state.purchase = action.payload.data;
    },
    setPurchasesBySuppIdObj(state, action) {
      state.purchasesBySuppId = action.payload.data;
    },
  },
});

export const { addPurchase, removepurchase,setPurchasePrint,setPurchasesBySuppIdObj } = PurchaseSlice.actions;
export default PurchaseSlice.reducer;
