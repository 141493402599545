import { AxiosRequestConfig } from "axios";
import { ILogin } from "../../Common/Interfaces/Login/SuperRegister";
import axiosServiceInstance from "../AxiosInstanceService";
import { baseUrl, getCOnfigHeader } from "../../Common/Config/Config";

export const superLoginService = (payload: ILogin) => {
  let config: AxiosRequestConfig = getCOnfigHeader();
  return axiosServiceInstance.post(`${baseUrl}/api/super/superUserLogin`, payload, config)
}

export const getSuperLoginDataService = (id: string) => {
  let config: AxiosRequestConfig = getCOnfigHeader();
  return axiosServiceInstance.get(`${baseUrl}/api/super/superlogindata/${id}`, config);
}

