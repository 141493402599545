export enum CRUD_MESSAGES{
SAVE_MSG = "Data Saved Successfully",
EDIT_MSG = "Data Updated Successfully",
DELETE_MSG  = "Data Deleted Successfully!"
}

export enum LOGIN_MESSAGES{
    CREATE_ACC = "Account Create Successfully",
    LOGIN_SUCC = "Login Successfully",
    UNAME_ERROR="Email Must Be Unique",
    LOGIN_ERROR="Incorrect Email Or Password"
}

export enum REPORT_MESSAGES{
    GENERATE_LEDGER="Generated Ledger Report Successfully",
    GENERATE_STOCK="Generated Stock Report Successfully",
    GENERATE_SALES="Generated Sales Report Successfully",
    GENERATE_ITEMWISESALES="Generated Item Wise Sales Successfully",
    GENERATE_DELIVERYCHALLLAN="Generated Delivery Challan Report Successfully",
    GENERATE_PURCHASE="Generated Purchase Report Successfully",
    GENERATE_ITEMWISEPURCHASE="Generated Itemwise Purchase Successfully",
    GENERATE_PARTYWISEOUTSTANDING="Generated Partywise Outstanding Successfully",
    GENERATE_CATEGORYWISESTOCK="Generated Categorywise Stock Successfully",
    GENERATE_SALESRETURN="Generated Sales Report Successfully",
    GENERATE_QUOTATIONREPORT="Generated Quotation Report Successfully",
    GENERATE_ITEMLEDGER="Generated ItemLedger Report Successfully",
    GENERATE_PURCHASERETURN="Generated Purchase Return Report Successfully",
    GENERATE_DELIVERYCHALLANRETURN="Generated Delivery Challan Return Report successfully",
    GENERATE_PURCHASEORDER="Generated Purchase Order Report Successfully",
    GENERATE_PURCHASECHALLAN="Generated Purchase Challan Report Successfully",
    GENERATE_PURCHASECHALLANRETURN="Generated Purchase Challan Return Report Successfully",

}

export enum VOUCHER_MESSAGE{
    VOUCHER_CREATED="Voucher Created SuccessFully"
}
export enum TAX_TYPE{
    NON_GST="NON GST",
    INCLUSIVE="INCLUSIVE",
    EXCLUSIVE="EXCLUSIVE"
}

export enum PAY_MODE{
    CASH="CASH",
    CREDIT="CREDIT",
    BANK="BANK"
}

export enum SALE_RETURN_PAY_MODE{
    CASH="CASH",
    CREDIT="CREDIT NOTE"
}

export enum TIME_PERIOD{
    TODAYS="Todays",
    CURRENT_MONTH="Current month",
    LAST_MONTH="Last month",
    LAST_THREE_MONTHS="Last 3 months",
    LAST_SIX_MONTHS="Last 6 months",
    CUSTOM="Custom"
}

export enum PARTY_REPORT_TIME_PERIOD{
    TODAYS="Todays",
    CUSTOM="Custom"
}

export enum ACCOUNT_GROUP_MASTER{
    SALES= "3e0d02df-e5b0-11ee-82a7-c4651602d175",
    PURCHASE= "3e0d1920-e5b0-11ee-82a7-c4651602d175",
    CURRENTASSETS= "3e0d1997-e5b0-11ee-82a7-c4651602d175",
    CURRENTLIABILITIES= "3e0d1a89-e5b0-11ee-82a7-c4651602d175",
    DIRECTEXPENSES= "3e0d1aae-e5b0-11ee-82a7-c4651602d175",
    INDIRECTEXPENSES= "3e0d1acf-e5b0-11ee-82a7-c4651602d175",
    BANKACCOUNTS= "3e0d1aed-e5b0-11ee-82a7-c4651602d175",
    BANKODAC= "3e0d1b0f-e5b0-11ee-82a7-c4651602d175",
    BRANCHDIVISION= "3e0d1b2e-e5b0-11ee-82a7-c4651602d175",
    CAPITALACCOUNT= "3e0d1b4a-e5b0-11ee-82a7-c4651602d175",
    CASHINHAND= "3e0d1b68-e5b0-11ee-82a7-c4651602d175",
    DEPOSITSASSETS= "3e0d1b82-e5b0-11ee-82a7-c4651602d175",
    DIRECTINCOMES= "3e0d1b9c-e5b0-11ee-82a7-c4651602d175",
    DUTIESANDTAXES= "3e0d1bb5-e5b0-11ee-82a7-c4651602d175",
    FIXEDASSETS= "3e0d1bd2-e5b0-11ee-82a7-c4651602d175",
    INDIRECTINCOMES= "3e0d1bef-e5b0-11ee-82a7-c4651602d175",
    INVESTMENTS= "3e0d1c08-e5b0-11ee-82a7-c4651602d175",
    LOANANDADVANCESASSETS= "3e0d1c25-e5b0-11ee-82a7-c4651602d175",
    LOANSLIABILITY= "3e0d1c3f-e5b0-11ee-82a7-c4651602d175",
    MISCEXPENSES= "3e0d1c57-e5b0-11ee-82a7-c4651602d175",
    PROVISIONS= "3e0d1c71-e5b0-11ee-82a7-c4651602d175",
    RESERVESANDSURPLUS= "3e0d1c93-e5b0-11ee-82a7-c4651602d175",
    SECUREDLOANS= "3e0d1cda-e5b0-11ee-82a7-c4651602d175",
    STOCKINHAND= "3e0d1cf3-e5b0-11ee-82a7-c4651602d175",
    SUNDRYCREDITORS= "3e0d1d3b-e5b0-11ee-82a7-c4651602d175",
    SUNDRYDEBTORS= "3e0d1d55-e5b0-11ee-82a7-c4651602d175",
    SUSPENSEAC= "3e0d1d70-e5b0-11ee-82a7-c4651602d175",
    UNSECUREDLOAN= "3e0d1d89-e5b0-11ee-82a7-c4651602d175",
    SALARY= "4a4f14a6-e5b1-11ee-82a7-c4651602d175",
    COMMISSIONAGENT= "4a4f26d1-e5b1-11ee-82a7-c4651602d175",
  };

