import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: {},
  status: STATUSES.IDLE,
};
const ReportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    setLedger(state, action) {
      state.ledgerData = action.payload.data;
    },
    setStock(state, action) {
      state.stockData = action.payload.data;
    },
    setSale(state, action) {
      state.saleData = action.payload.data;
    },
    setQuotation(state, action) {
      state.quotationData = action.payload.data;
    },
    setSalesReturn(state, action) {
      state.salereturnData = action.payload.data;
    },
    setDeliveryChallan(state, action) {
      state.dcData = action.payload.data;
    },
    setDCReturn(state, action) {
      state.dcreturnData = action.payload.data;
    },
    setItemWiseSales(state, action) {
      state.itemWiseSales = action.payload.data;
    },
    setPurchase(state, action) {
      state.purchaseData = action.payload.data;
    },
    setPurchaseReturn(state, action) {
      state.purchasereturnData = action.payload.data;
    },
    setPurchaseOrder(state, action) {
      state.purchaseorderData = action.payload.data;
    },
    setPurchaseChallan(state, action) {
      state.purchasechallanData = action.payload.data;
    },
    setPCReturn(state, action) {
      state.pcreturnData = action.payload.data;
    },
    setCategoryWiseStock(state, action) {
      state.categorywisestock = action.payload;
    },
    setItemLedger(state, action) {
      state.itemledger = action.payload.data;
    },
    setOpeningBalance(state, action) {
      state.openingBalance = action.payload.data;
    },
    setReportData(state, action) {
      state.reportData = action.payload;
    },
    setItemWisePurchase(state, action) {
      state.itemWisePurchase = action.payload.data;
    },
    setStockForSales(state, action) {
      state.stockForSales = action.payload.data;
    },

    setCustomerOutstandingData(state, action) {
      state.customerOutstandingData = action.payload.data;
    },
    setSupplierOutstandingData(state, action) {
      state.supplierOutstandingData = action.payload.data;
    },
  },
});

export const {
  setLedger,
  setStock,
  setSale,
  setItemWiseSales,
  setReportData,
  setPurchase,
  setPurchaseChallan,
  setItemLedger,
  setSalesReturn,
  setDeliveryChallan,
  setDCReturn,
  setItemWisePurchase,
  setCategoryWiseStock,
  setStockForSales,
  setCustomerOutstandingData,
  setSupplierOutstandingData,
  setQuotation,
  setPurchaseOrder,
  setPurchaseReturn,
  setPCReturn,
} = ReportsSlice.actions;
export default ReportsSlice.reducer;
