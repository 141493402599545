import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  status: STATUSES.IDLE,
};
const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setDashboardData(state, action) {
      state.dashboardData = action.payload.data;
    },
    setCustomerOutstandingData(state, action) {
      state.customerOutstandingData = action.payload.data;
    },
    setSupplierOutstandingData(state, action) {
      state.supplierOutstandingData = action.payload.data;
    },
  },
});

export const {
  setDashboardData,
  setCustomerOutstandingData,
  setSupplierOutstandingData,
} = DashboardSlice.actions;
export default DashboardSlice.reducer;
