import { FormikValues } from "formik";

export function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export function formatCustomDate(inputDate: string) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
}

export const handleTimePeriodChange = (e: any, formik: FormikValues) => {
  function formatDate(date: Date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  const today = new Date();
  if (e.target.value === "Todays") {
    const todaysDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    formik.setFieldValue("FromDate", formatDate(todaysDay));
    formik.setFieldValue("ToDate", formatDate(todaysDay));
  } else if (e.target.value === "Current month") {
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    formik.setFieldValue("FromDate", formatDate(firstDayOfMonth));
    formik.setFieldValue("ToDate", formatDate(lastDayOfMonth));
  } else if (e.target.value === "Last month") {
    const firstDayOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate()
    );
    const lastDayOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    formik.setFieldValue("FromDate", formatDate(firstDayOfLastMonth));
    formik.setFieldValue("ToDate", formatDate(lastDayOfLastMonth));
  } else if (e.target.value === "Last 3 months") {
    const firstDayOfLast3Months = new Date(
      today.getFullYear(),
      today.getMonth() - 3,
      today.getDate()
    );
    const lastDayOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    formik.setFieldValue("FromDate", formatDate(firstDayOfLast3Months));
    formik.setFieldValue("ToDate", formatDate(lastDayOfLastMonth));
  } else if (e.target.value === "Last 6 months") {
    const firstDayOfLast6Months = new Date(
      today.getFullYear(),
      today.getMonth() - 6,
      today.getDate()
    );
    const lastDayOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    formik.setFieldValue("FromDate", formatDate(firstDayOfLast6Months));
    formik.setFieldValue("ToDate", formatDate(lastDayOfLastMonth));
  }
};
