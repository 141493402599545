import { AxiosRequestConfig } from "axios";
import { ILogin } from "../../Common/Interfaces/Login/SuperRegister";
import axiosServiceInstance from "../AxiosInstanceService";
import { baseUrl, getCOnfigHeader } from "../../Common/Config/Config";
import { store } from "../../store/store";

export const empLoginService = (payload: ILogin) => {
  let config: AxiosRequestConfig = {
    headers: { "content-type": "multipart/form-data" },
  };
  return axiosServiceInstance.post(
    `${baseUrl}/api/super/employeelogin`,
    payload,
    config
  );
};

export const getEmpLoginDataService = (id: string) => {
  const state = store.getState();
  const token = state.loginconfirm.data.idToken;
  let config: AxiosRequestConfig = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: token,
    },
  };
  return axiosServiceInstance.get(
    `${baseUrl}/api/super/emplogindata/${id}`,
    config
  );
};
