import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: {},
  status: STATUSES.IDLE,
};
const OutstandingSlice = createSlice({
  name: "outstanding",
  initialState: initialState,
  reducers: {
    setOpeningBalance(state, action) {
      state.openingBalance = action.payload.data;
    },
  },
});

export const { setOpeningBalance } = OutstandingSlice.actions;
export default OutstandingSlice.reducer;
