import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const DeliveryChallanReturnSlice = createSlice({
  name: "deliverychallanreturn",
  initialState,
  reducers: {
    addDeliveryChallanReturn(state, action) {
      state.data = action.payload.data;
    },
    removeDeliveryChallanReturn(state, action) {
      return state.filter((item: any) => item.id !== action.payload);
    },
    setDeliveryChallanReturnPrint(state, action) {
      state.deliverychallanreturn = action.payload.data;
    },
    setDeliveryChallanReturnsByCustId(state, action) {
      state.deliverychallanreturnByCustId = action.payload.data;
    },
    setDeliveryChallanItemsByCustId(state, action) {
      state.deliverychallanitems = action.payload.data;
    },
  },
});

export const {
  addDeliveryChallanReturn,
  removeDeliveryChallanReturn,
  setDeliveryChallanReturnPrint,
  setDeliveryChallanReturnsByCustId,
  setDeliveryChallanItemsByCustId
} = DeliveryChallanReturnSlice.actions;
export default DeliveryChallanReturnSlice.reducer;
