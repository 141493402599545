import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const PurchaseChallanReturnSlice = createSlice({
  name: "pcreturn",
  initialState,
  reducers: {
    addPurchaseChallanReturn(state, action) {
      state.data = action.payload.data;
    },
    removePurchaseChallanReturn(state, action) {
      return state.filter((item: any) => item.id !== action.payload);
    },
    setPurchaseChallanReturnPrint(state, action) {
      state.purchasechallanreturn = action.payload.data;
    },
    setPurchaseChallanItemsBySuppId(state, action) {
      state.purchasechallanitems = action.payload.data;
    },
  },
});

export const {
  addPurchaseChallanReturn,
  removePurchaseChallanReturn,
  setPurchaseChallanReturnPrint,
  setPurchaseChallanItemsBySuppId
} = PurchaseChallanReturnSlice.actions;
export default PurchaseChallanReturnSlice.reducer;
