import React, { lazy, useContext, useEffect } from "react";
/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
import { useAppSelector } from "../store/store";
// import PurchaseChallanReturnForm from "./components/Transactions/PurchaseChallanReturn/PurchaseChallanReturnForm";
// import DeliveryChallanReturn from "./components/Transactions/DeliveryChallanReturn/DeliveryChallanReturn";
// import PurchaseChallanReturn from "./components/Transactions/PurchaseChallanReturn/PurchaseChallanReturn";
// import DeliveryChallanReturnForm from "./components/Transactions/DeliveryChallanReturn/DeliveryChallanReturnForm";
// import DeliveryChallanInvoice from "./components/Transactions/DeliveryChallan/DeliveryChallanInvoice";
// import PurchaseReport from "./components/Reports/PurchaseReport/PurchaseReport";
// import ItemWiseSalesReport from "./components/Reports/ItemWiseSalesReport/ItemWiseSalesReport";
// import ItemwisePurchaseReport from "./components/Reports/ItemwisePurchaseReport/ItemwisePurchaseReport";
// import CategoryWiseStockReport from "./components/Reports/CategoryWiseStockReport/CategoryWiseStockReport";
// import PartyWiseOutstandingReport from "./components/Reports/PartyWiseOutstanding/PartyWiseOutstandingReport";
// import QuotationReport from "./components/Reports/QuotationReport/QuotationReport";
// import SalesReturnReport from "./components/Reports/SalesReturnReport/SalesReturnReport";
// import DeliveryChallanReport from "./components/Reports/DeliveryChallanReport/DeliveryChallanReport";
// import ItemLedgerReport from "./components/Reports/ItemLedgerReport/ItemLedgerReport";
// import PurchaseReturnReport from "./components/Reports/PurchaseReturnReport/PurchaseReturnReport";
// import PurchaseOrderReport from "./components/Reports/PurchaseOrderReport/PurchaseOrderReport";
// import PurchaseChallanReport from "./components/Reports/PurchaseChallanReport/PurchaseChallanReport";
// import PurchaseChallanReturnReport from "./components/Reports/PurchaseChallanReturnReport/PurchaseChallanReturnReport";
// import DeliveryChallanReturnReport from "./components/Reports/DeliveryChallanReturnReport/DeliveryChallanReturnReport";

// import Dashboard from "./components/Dashboard/Dashboard";
// import  ManufacturerMaster  from "./components/Masters/Manufacturer/ManufacturerMaster";
// import CategoryMaster from "./components/Masters/Category/CategoryMaster";
// import  ExtraChargeMaster  from "./components/Masters/ExtraCharge/ExtraChargeMaster";
// import FirmMaster from "./components/Masters/Firm/FirmMaster";
// import ItemMaster from "./components/Masters/Item/ItemMaster";
// import PartyMaster from "./components/Masters/Party/PartyMaster";
// import TermsAndConditionMaster from "./components/Masters/TermsAndCondition/TermsAndConditionMaster";
// import UnitMaster from "./components/Masters/Unit/UnitMaster";

// import SalesTransaction from "./components/Transactions/Sales/SalesTransaction";
// import QuotationTransaction from "./components/Transactions/Quatation/QuotationTransaction";
// import SalesForm from "./components/Transactions/Sales/SalesForm";
// import QuotationForm from "./components/Transactions/Quatation/QuotationForm";
// import TabMaster from "./User Management/User-Master/TabMaster/TabMaster";
// import ActivityMaster from "./User Management/User-Master/ActivityMaster/ActivityMaster";
// import RoleMaster from "./User Management/User-Master/RoleMaster/RoleMaster";
// import IconMaster from "./User Management/User-Master/IconMaster/IconMaster";
// import Profile from "./User Management/User-Profile/Profile";
// import OfficeType from "./User Management/User-Office/Type/OfficeType";
// import OfficeBranch from "./User Management/User-Office/Branch/OfficeBranch";
// import OfficeEmployee from "./User Management/User-Office/Employee/OfficeEmployee";
// import ProfileControllerModal from "./User Management/User-Profile/ProfileController/ProfileControllerModal";
// import SalesReturnTransaction from "./components/Transactions/SalesReturn/SalesReturnTransaction";
// import SalesReturnForm from "./components/Transactions/SalesReturn/SalesReturnForm";
// import SaleInvoice from "./components/Transactions/Sales/SalesInvoice";
// import QuotationInvoice from "./components/Transactions/Quatation/QuotationInvoice";
// import PurchaseTransaction from "./components/Transactions/Purchase/PurchaseTransaction";
// import PurchaseForm from "./components/Transactions/Purchase/PurchaseForm";

//dashboard
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));

//masters
const UnitMaster = lazy(() => import("./components/Masters/Unit/UnitMaster"));
const CategoryMaster = lazy(
  () => import("./components/Masters/Category/CategoryMaster")
);
const ManufacturerMaster = lazy(
  () => import("./components/Masters/Manufacturer/ManufacturerMaster")
);
const FirmMaster = lazy(() => import("./components/Masters/Firm/FirmMaster"));
const ItemMaster = lazy(() => import("./components/Masters/Item/ItemMaster"));
const PartyMaster = lazy(
  () => import("./components/Masters/Party/PartyMaster")
);
const ExtraChargeMaster = lazy(
  () => import("./components/Masters/ExtraCharge/ExtraChargeMaster")
);
const TermsAndConditionMaster = lazy(
  () => import("./components/Masters/TermsAndCondition/TermsAndConditionMaster")
);
const LedgerMaster = lazy(
  () => import("./components/Masters/Ledger/LedgerMaster")
);

//Transactions
const SalesTransaction = lazy(
  () => import("./components/Transactions/Sales/SalesTransaction")
);
const QuotationTransaction = lazy(
  () => import("./components/Transactions/Quatation/QuotationTransaction")
);
const SalesForm = lazy(
  () => import("./components/Transactions/Sales/SalesForm")
);
const QuotationForm = lazy(
  () => import("./components/Transactions/Quatation/QuotationForm")
);
const SalesReturnTransaction = lazy(
  () => import("./components/Transactions/SalesReturn/SalesReturnTransaction")
);
const SalesReturnForm = lazy(
  () => import("./components/Transactions/SalesReturn/SalesReturnForm")
);
const SaleInvoice = lazy(
  () => import("./components/Transactions/Sales/SalesInvoice")
);
const QuotationInvoice = lazy(
  () => import("./components/Transactions/Quatation/QuotationInvoice")
);
const PurchaseTransaction = lazy(
  () => import("./components/Transactions/Purchase/PurchaseTransaction")
);
const PurchaseOrder = lazy(
  () => import("./components/Transactions/PurchaseOrder/PurchaseOrder")
);
const PurchaseForm = lazy(
  () => import("./components/Transactions/Purchase/PurchaseForm")
);
const DeliveryChallanForm = lazy(
  () => import("./components/Transactions/DeliveryChallan/DeliveryChallanForm")
);
const DeliveryChallan = lazy(
  () => import("./components/Transactions/DeliveryChallan/DeliveryChallan")
);
const PurchaseInvoice = lazy(
  () => import("./components/Transactions/Purchase/PurchaseInvoice")
);
const PurchaseReturnForm = lazy(
  () => import("./components/Transactions/PurchaseReturn/PurchaseReturnForm")
);
const PurchaseOrderForm = lazy(
  () => import("./components/Transactions/PurchaseOrder/PurchaseOrderForm")
);
const PurchaseOrderInvoice = lazy(
  () => import("./components/Transactions/PurchaseOrder/PurchaseOrderInvoice")
);
const PurchaseChallan = lazy(
  () => import("./components/Transactions/PurchaseChallan/PurchaseChallan")
);
const PurchaseChallanForm = lazy(
  () => import("./components/Transactions/PurchaseChallan/PurchaseChallanForm")
);
const PurchaseChallanInvoice = lazy(
  () =>
    import("./components/Transactions/PurchaseChallan/PurchaseChallanInvoice")
);
const PurchaseReturnTransaction = lazy(
  () =>
    import("./components/Transactions/PurchaseReturn/PurchaseReturnTransaction")
);

//Vouchers
const PaymentVoucher = lazy(
  () => import("./components/Vouchers/Payment/PaymentVoucher")
);
const ReceiptVoucher = lazy(
  () => import("./components/Vouchers/Receipt/ReceiptVoucher")
);
const ContraVoucher = lazy(
  () => import("./components/Vouchers/ContraVoucher/ContraVoucher")
);

// Reports
const Reports = lazy(() => import("./components/Reports/Reports"));
const LedgerReport = lazy(
  () => import("./components/Reports/LedgerReport/LedgerReport")
);
const StockReport = lazy(
  () => import("./components/Reports/StockReport/StockReport")
);
const SalesReport = lazy(
  () => import("./components/Reports/SalesReport/SalesReport")
);

//User Management
//masters
const TabMaster = lazy(
  () => import("./User Management/User-Master/TabMaster/TabMaster")
);
const ActivityMaster = lazy(
  () => import("./User Management/User-Master/ActivityMaster/ActivityMaster")
);
const RoleMaster = lazy(
  () => import("./User Management/User-Master/RoleMaster/RoleMaster")
);
const IconMaster = lazy(
  () => import("./User Management/User-Master/IconMaster/IconMaster")
);

//User Management
//profile
const Profile = lazy(() => import("./User Management/User-Profile/Profile"));
const ProfileControllerModal = lazy(
  () =>
    import(
      "./User Management/User-Profile/ProfileController/ProfileControllerModal"
    )
);

//User Management
//office
const OfficeType = lazy(
  () => import("./User Management/User-Office/Type/OfficeType")
);
const OfficeBranch = lazy(
  () => import("./User Management/User-Office/Branch/OfficeBranch")
);
const OfficeEmployee = lazy(
  () => import("./User Management/User-Office/Employee/OfficeEmployee")
);

//error pages
const Error400 = lazy(() => import("./pages/Error400"));
const Error403 = lazy(() => import("./pages/Error403"));
const Error404 = lazy(() => import("./pages/Error404"));
const Error500 = lazy(() => import("./pages/Error500"));
const Error503 = lazy(() => import("./pages/Error503"));
const Error401Session = lazy(() => import("./pages/Error401Session"));

const PurchaseChallanReturnForm = lazy(
  () =>
    import(
      "./components/Transactions/PurchaseChallanReturn/PurchaseChallanReturnForm"
    )
);
const DeliveryChallanReturn = lazy(
  () =>
    import(
      "./components/Transactions/DeliveryChallanReturn/DeliveryChallanReturn"
    )
);
const PurchaseChallanReturn = lazy(
  () =>
    import(
      "./components/Transactions/PurchaseChallanReturn/PurchaseChallanReturn"
    )
);
const DeliveryChallanReturnForm = lazy(
  () =>
    import(
      "./components/Transactions/DeliveryChallanReturn/DeliveryChallanReturnForm"
    )
);
const DeliveryChallanInvoice = lazy(
  () =>
    import("./components/Transactions/DeliveryChallan/DeliveryChallanInvoice")
);
const PurchaseReport = lazy(
  () => import("./components/Reports/PurchaseReport/PurchaseReport")
);
const ItemWiseSalesReport = lazy(
  () => import("./components/Reports/ItemWiseSalesReport/ItemWiseSalesReport")
);
const ItemwisePurchaseReport = lazy(
  () =>
    import("./components/Reports/ItemwisePurchaseReport/ItemwisePurchaseReport")
);
const CategoryWiseStockReport = lazy(
  () =>
    import(
      "./components/Reports/CategoryWiseStockReport/CategoryWiseStockReport"
    )
);
const PartyWiseOutstandingReport = lazy(
  () =>
    import(
      "./components/Reports/PartyWiseOutstanding/PartyWiseOutstandingReport"
    )
);
const QuotationReport = lazy(
  () => import("./components/Reports/QuotationReport/QuotationReport")
);
const SalesReturnReport = lazy(
  () => import("./components/Reports/SalesReturnReport/SalesReturnReport")
);
const DeliveryChallanReport = lazy(
  () =>
    import("./components/Reports/DeliveryChallanReport/DeliveryChallanReport")
);
const ItemLedgerReport = lazy(
  () => import("./components/Reports/ItemLedgerReport/ItemLedgerReport")
);
const PurchaseReturnReport = lazy(
  () => import("./components/Reports/PurchaseReturnReport/PurchaseReturnReport")
);
const PurchaseOrderReport = lazy(
  () => import("./components/Reports/PurchaseOrderReport/PurchaseOrderReport")
);
const PurchaseChallanReport = lazy(
  () =>
    import("./components/Reports/PurchaseChallanReport/PurchaseChallanReport")
);
const PurchaseChallanReturnReport = lazy(
  () =>
    import(
      "./components/Reports/PurchaseChallanReturnReport/PurchaseChallanReturnReport"
    )
);
const DeliveryChallanReturnReport = lazy(
  () =>
    import(
      "./components/Reports/DeliveryChallanReturnReport/DeliveryChallanReturnReport"
    )
);

/// Dashboard
/*import Home from "./components/Dashboard/Home";
import Analytics from "./components/Dashboard/Analytics";
import Customers from "./components/Dashboard/Customers";
import Event from "./components/Dashboard/Event";
import EventDetail from "./components/Dashboard/EventDetail";
import Reviews from "./components/Dashboard/Reviews";
import Task from './components/Dashboard/Task';
*/

/// App
/*import AppProfile from './components/AppsMenu/AppProfile/AppProfile'
import PostDetails from './components/AppsMenu/AppProfile/PostDetails'
import Compose from './components/AppsMenu/Email/Compose/Compose'
import Inbox from './components/AppsMenu/Email/Inbox/Inbox'
import Read from './components/AppsMenu/Email/Read/Read'
import Calendar from './components/AppsMenu/Calendar/Calendar'
*/
/// Product List
/*import ProductGrid from './components/AppsMenu/Shop/ProductGrid/ProductGrid'
import ProductList from './components/AppsMenu/Shop/ProductList/ProductList'
import ProductDetail from './components/AppsMenu/Shop/ProductGrid/ProductDetail'
import Checkout from './components/AppsMenu/Shop/Checkout/Checkout'
import Invoice from './components/AppsMenu/Shop/Invoice/Invoice'
import ProductOrder from './components/AppsMenu/Shop/ProductOrder'
import EcomCustomers from './components/AppsMenu/Shop/Customers/Customers'
*/
/// Charts
/*import RechartJs from './components/charts/rechart'
import ChartJs from './components/charts/Chartjs'
*/
//import Chartist from './components/charts/chartist'
/*import SparklineChart from './components/charts/Sparkline'
import ApexChart from './components/charts/apexcharts'
*/

/// Bootstrap
/*import UiAlert from './components/bootstrap/Alert'
import UiAccordion from './components/bootstrap/Accordion'
import UiBadge from './components/bootstrap/Badge'
import UiButton from './components/bootstrap/Button'
import UiModal from './components/bootstrap/Modal'
import UiButtonGroup from './components/bootstrap/ButtonGroup'
import UiListGroup from './components/bootstrap/ListGroup'
import UiCards from './components/bootstrap/Cards'
import UiCarousel from './components/bootstrap/Carousel'
import UiDropDown from './components/bootstrap/DropDown'
import UiPopOver from './components/bootstrap/PopOver'
import UiProgressBar from './components/bootstrap/ProgressBar'
import UiTab from './components/bootstrap/Tab'
import UiPagination from './components/bootstrap/Pagination'
import UiGrid from './components/bootstrap/Grid'
import UiTypography from './components/bootstrap/Typography'
*/
/// Plugins
/*import Select2 from './components/PluginsMenu/Select2/Select2'*/
//import Nestable from './components/PluginsMenu/Nestable/Nestable'
//import MainNouiSlider from './components/PluginsMenu/Noui Slider/MainNouiSlider'
/*import MainSweetAlert from './components/PluginsMenu/SweetAlert/SweetAlert'
import Toastr from './components/PluginsMenu/Toastr/Toastr'
import JqvMap from './components/PluginsMenu/JqvMap/JqvMap'
import Lightgallery from './components/PluginsMenu/Lightgallery/Lightgallery'
*/

/// Widget
/*import Widget from './pages/Widget'*/

/// Table
/*import DataTable from './components/table/DataTable'
import BootstrapTable from './components/table/BootstrapTable'
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
*/

/// Form
//import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
//import WizardForm from "./components/Forms/ReduxWizard/Index";
/*import Element from './components/Forms/Element/Element'
import Wizard from './components/Forms/Wizard/Wizard'
import CkEditor from './components/Forms/CkEditor/CkEditor'
import Pickers from './components/Forms/Pickers/Pickers'
import FormValidation from "./components/Forms/FormValidation/FormValidation";
*/
/// Pages
/*import Registration from './pages/Registration'
import Login from './pages/Login'
import LockScreen from './pages/LockScreen'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'
import Todo from './pages/Todo';
*/
//Scroll To Top
/*import ScrollToTop from './layouts/ScrollToTop';*/

const Markup = () => {
  // let path = window.location.pathname
  // path = path.split('/')
  // path = path[path.length - 1]
  // let pagePath = path.split('-').includes('page')
  // const [activeEvent, setActiveEvent] = useState(!path)

  const allroutes: any[] = [
    // Dashboard
    { url: "", component: <Dashboard /> },

    //Masters
    { url: "dashboard", component: <Dashboard /> },
    { url: "firm", component: <FirmMaster /> },
    { url: "category", component: <CategoryMaster /> },
    { url: "unit", component: <UnitMaster /> },
    { url: "item", component: <ItemMaster /> },
    { url: "party", component: <PartyMaster /> },
    { url: "manufacturer", component: <ManufacturerMaster /> },
    { url: "extracharges", component: <ExtraChargeMaster /> },
    { url: "tandc", component: <TermsAndConditionMaster /> },
    { url: "extracharges", component: <ExtraChargeMaster /> },
    { url: "ledger", component: <LedgerMaster /> },

    // Transactions
    //------------( sales )---------------
    { url: "deliverychallan", component: <DeliveryChallan /> },
    { url: "deliverychallanreturn", component: <DeliveryChallanReturn /> },
    { url: "quotation", component: <QuotationTransaction /> },
    { url: "sales", component: <SalesTransaction /> },
    { url: "salesreturn", component: <SalesReturnTransaction /> },

    //----------( purchase )----------
    { url: "purchasechallan", component: <PurchaseChallan /> },
    { url: "purchasechallanreturn", component: <PurchaseChallanReturn /> },
    { url: "purchaseorder", component: <PurchaseOrder /> },
    { url: "purchase", component: <PurchaseTransaction /> },
    { url: "purchasereturn", component: <PurchaseReturnTransaction /> },

    // Vouchers
    { url: "payment", component: <PaymentVoucher /> },
    { url: "receipt", component: <ReceiptVoucher /> },
    { url: "contravoucher", component: <ContraVoucher /> },
    // Reports
    { url: "reports", component: <Reports /> },

    //User management/Master
    { url: "tab", component: <TabMaster /> },
    { url: "activity", component: <ActivityMaster /> },
    { url: "role", component: <RoleMaster /> },
    { url: "iconmaster", component: <IconMaster /> },
    //User management/Profile
    { url: "profile", component: <Profile /> },
    { url: "profilecontrol", component: <ProfileControllerModal /> },
    //User management/Office
    { url: "type", component: <OfficeType /> },
    { url: "branch", component: <OfficeBranch /> },
    { url: "employee", component: <OfficeEmployee /> },

    /// Apps
    /*{ url: 'app-profile', component: <AppProfile/> },
    { url: 'post-details', component: <PostDetails/> },
    { url: 'email-compose', component: <Compose/> },
    { url: 'email-inbox', component: <Inbox/> },
    { url: 'email-read', component: <Read/> },
    { url: 'app-calender', component: <Calendar/> },
    */

    /// Chart
    /* { url: 'chart-sparkline', component: <SparklineChart/> },
    { url: 'chart-chartjs', component: <ChartJs/> },
    //{ url: 'chart-chartist', component: Chartist },
    { url: 'chart-apexchart', component: <ApexChart/> },
    { url: 'chart-rechart', component: <RechartJs/> },
*/
    /// Bootstrap
    /* { url: 'ui-alert', component: <UiAlert/> },
    { url: 'ui-badge', component: <UiBadge/> },
    { url: 'ui-button', component: <UiButton/> },
    { url: 'ui-modal', component: <UiModal/> },
    { url: 'ui-button-group', component: <UiButtonGroup/> },
    { url: 'ui-accordion', component: <UiAccordion/> },
    { url: 'ui-list-group', component: <UiListGroup/> },
    { url: 'ui-card', component: <UiCards/> },
    { url: 'ui-carousel', component: <UiCarousel/> },
    { url: 'ui-dropdown', component: <UiDropDown/> },
    { url: 'ui-popover', component: <UiPopOver/> },
    { url: 'ui-progressbar', component: <UiProgressBar/> },
    { url: 'ui-tab', component: <UiTab/> },
    { url: 'ui-pagination', component: <UiPagination/> },
    { url: 'ui-typography', component: <UiTypography/> },
    { url: 'ui-grid', component: <UiGrid/> },
*/
    /// Plugin
    /* { url: 'uc-select2', component: <Select2/> },*/
    //{ url: 'uc-nestable', component: Nestable },
    //{ url: 'uc-noui-slider', component: MainNouiSlider },
    /* { url: 'uc-sweetalert', component: <MainSweetAlert/> },
    { url: 'uc-toastr', component: <Toastr/> },
    { url: 'map-jqvmap', component: <JqvMap/> },
    { url: 'uc-lightgallery', component: <Lightgallery/> },
*/

    /// Widget
    /*{ url: 'widget-basic', component: <Widget /> },*/

    /// Shop
    /* { url: 'ecom-product-grid', component: <ProductGrid/> },
    { url: 'ecom-product-list', component: <ProductList/> },
    { url: 'ecom-product-detail', component: <ProductDetail/> },
    { url: 'ecom-product-order', component: <ProductOrder/> },
    { url: 'ecom-checkout', component: <Checkout/> },
    { url: 'ecom-invoice', component: <Invoice/> },
    { url: 'ecom-product-detail', component: <ProductDetail/> },
    { url: 'ecom-customers', component: <EcomCustomers/> },
*/
    /// Form

    //{ url: 'form-redux', component: ReduxForm },
    //{ url: 'form-redux-wizard', component: WizardForm },
    /* { url: 'form-element', component: <Element/> },
    { url: 'form-wizard', component: <Wizard/> },
    { url: "form-ckeditor", component: <CkEditor /> },
    { url: 'form-pickers', component: <Pickers/> },
    { url: "form-validation", component: <FormValidation /> },
*/
    /// table
    /* { url: 'table-datatable-basic', component: <DataTable/> },
    { url: 'table-bootstrap-basic', component: <BootstrapTable/> },
    { url: 'table-filtering', component: <FilteringTable/> },
    { url: 'table-sorting', component: <SortingTable /> },
*/
    /// pages
    /*  { url: 'page-register', component: <Registration/> },
    { url: 'page-lock-screen', component: <LockScreen/> },
    { url: 'page-login', component: <Login/> },
    { url: 'todo', component: <Todo/> },*/
  ];

  let filteredRoutes = allroutes;
  const loginData = useAppSelector((state: any) => state.commonlogin.data);
  if (loginData.type === "employee") {
    const { activity } = loginData;
    filteredRoutes = allroutes.filter((route: any) => {
      return activity.some((activity: any) => activity.url === route.url);
    });
  }

  return (
    <>
      <Routes>
        <Route path="page-error-400" element={<Error400 />} />
        <Route path="page-error-403" element={<Error403 />} />
        <Route path="page-error-500" element={<Error500 />} />
        <Route path="page-error-503" element={<Error503 />} />
        <Route path="deliverychallanform" element={<DeliveryChallanForm />} />
        <Route path="dcform/:dcId" element={<DeliveryChallanForm />} />{" "}
        <Route path="dcreturnform" element={<DeliveryChallanReturnForm />} />
        <Route
          path="dcreturnform/:dcreturnId"
          element={<DeliveryChallanReturnForm />}
        />
        <Route path="quotationform" element={<QuotationForm />} />
        <Route path="quotationform/:quoteId" element={<QuotationForm />} />
        <Route path="salesform" element={<SalesForm />} />
        <Route path="salesform/:salesId" element={<SalesForm />} />
        <Route path="salesreturnform" element={<SalesReturnForm />} />
        <Route
          path="salesreturnform/:salesreturnId"
          element={<SalesReturnForm />}
        />
        <Route path="purchasechallanform" element={<PurchaseChallanForm />} />
        <Route
          path="purchasechallanform/:pcId"
          element={<PurchaseChallanForm />}
        />
        <Route
          path="purchasechallanreturnform"
          element={<PurchaseChallanReturnForm />}
        />
        <Route
          path="purchasechallanreturnform/:pcreturnId"
          element={<PurchaseChallanReturnForm />}
        />
        <Route path="purchaseorderform" element={<PurchaseOrderForm />} />
        <Route path="purchaseorderform/:poId" element={<PurchaseOrderForm />} />
        <Route path="purchaseform" element={<PurchaseForm />} />
        <Route path="purchaseform/:purchaseformId" element={<PurchaseForm />} />
        <Route path="purchasereturnform" element={<PurchaseReturnForm />} />
        <Route
          path="purchasereturnform/:purchaseReturnId"
          element={<PurchaseReturnForm />}
        />
        <Route path="salesinvoice" element={<SaleInvoice />} />
        <Route path="purchaseorderinvoice" element={<PurchaseOrderInvoice />} />
        <Route path="purchaseinvoice" element={<PurchaseInvoice />} />
        <Route
          path="purchasechallaninvoice"
          element={<PurchaseChallanInvoice />}
        />
        <Route path="quotationinvoice" element={<QuotationInvoice />} />
        <Route path="dcinvoice" element={<DeliveryChallanInvoice />} />
        <Route path="ledgerreport" element={<LedgerReport />} />
        <Route path="stockreport" element={<StockReport />} />
        <Route path="salesreport" element={<SalesReport />} />
        <Route path="purchasereport" element={<PurchaseReport />} />
        <Route path="itemwisesalesreport" element={<ItemWiseSalesReport />} />
        <Route
          path="categorywisestockreport"
          element={<CategoryWiseStockReport />}
        />
        <Route path="purchasereturnreport" element={<PurchaseReturnReport />} />
        <Route path="itemledgerreport" element={<ItemLedgerReport />} />
        <Route
          path="purchasechallanreport"
          element={<PurchaseChallanReport />}
        />
        <Route
          path="pcreturnreport"
          element={<PurchaseChallanReturnReport />}
        />
        <Route
          path="itemwisepurchasereport"
          element={<ItemwisePurchaseReport />}
        />
        <Route
          path="partywiseoutstanding"
          element={<PartyWiseOutstandingReport />}
        />
        <Route path="purchaseorderreport" element={<PurchaseOrderReport />} />
        <Route
          path="deliverychallanreport"
          element={<DeliveryChallanReport />}
        />
        <Route
          path="dcreturnreport"
          element={<DeliveryChallanReturnReport />}
        />
        <Route path="salesreturnreport" element={<SalesReturnReport />} />
        <Route path="quotationreport" element={<QuotationReport />} />
        {/* <Route path="*" element={<Error404 />} /> */}
        <Route
          path="*"
          element={
            localStorage.getItem("userDetails") !== null ? (
              <Error404 />
            ) : (
              <Error401Session />
            )
          }
        />
        <Route path="purchasereturnform" element={<PurchaseReturnForm />} />
        <Route element={<MainLayout />}>
          {/* {allroutes.map((data, i) => (
            <Route
              key={i}
              //exact
              path={`${data.url}`}
              element={data.component}
            />
          ))} */}
          {filteredRoutes.map((data, i) => (
            <Route
              key={i}
              //exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  return (
    <div id="main-wrapper" className={`show `}>
      <Nav
        title={undefined}
        onClick={undefined}
        onClick2={undefined}
        onClick3={undefined}
      />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
